export const imagePaths = [
  require('assets/images/Brands/Brand_1.jpeg'),
  require('assets/images/Brands/Brand_10.jpeg'),
  require('assets/images/Brands/Brand_11.jpeg'),
  require('assets/images/Brands/Brand_12.jpeg'),
  require('assets/images/Brands/Brand_13.jpeg'),
  require('assets/images/Brands/Brand_14.jpeg'),
  require('assets/images/Brands/Brand_15.jpeg'),
  require('assets/images/Brands/Brand_16.jpeg'),
  require('assets/images/Brands/Brand_17.jpeg'),
  require('assets/images/Brands/Brand_18.jpeg'),
  require('assets/images/Brands/Brand_19.jpeg'),
  require('assets/images/Brands/Brand_2.jpeg'),
  require('assets/images/Brands/Brand_20.jpeg'),
  require('assets/images/Brands/Brand_21.jpeg'),
  require('assets/images/Brands/Brand_22.jpeg'),
  require('assets/images/Brands/Brand_23.jpeg'),
  require('assets/images/Brands/Brand_24.jpeg'),
  require('assets/images/Brands/Brand_25.jpeg'),
  require('assets/images/Brands/Brand_26.jpeg'),
  require('assets/images/Brands/Brand_27.jpeg'),
  require('assets/images/Brands/Brand_28.jpeg'),
  require('assets/images/Brands/Brand_29.jpeg'),
  require('assets/images/Brands/Brand_3.jpeg'),
  require('assets/images/Brands/Brand_4.jpeg'),
  require('assets/images/Brands/Brand_5.jpeg'),
  require('assets/images/Brands/Brand_6.jpeg'),
  require('assets/images/Brands/Brand_7.jpeg'),
  require('assets/images/Brands/Brand_8.jpeg'),
  require('assets/images/Brands/Brand_9.jpeg')
];
