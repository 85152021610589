import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface OverlayContextType {
  showOverlay: () => void;
  hideOverlay: () => void;
}

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) throw new Error('useOverlay must be used within a OverlayProvider');
  return context;
};

export const OverlayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const showOverlay = () => setIsVisible(true);
  const hideOverlay = () => setIsVisible(false);

  return (
    <OverlayContext.Provider value={{ showOverlay, hideOverlay }}>
      {children}
      {isVisible && <Overlay />}
    </OverlayContext.Provider>
  );
};

// Overlay Component
const Overlay: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 text-custom-500 bg-black backdrop-blur-sm bg-opacity-5 flex justify-center items-center">
      <div className="grid-rows-2 justify-center items-center text-center">
        <div className="inline-block w-14 h-14 border-4 rounded-full animate-spin border-l-transparent border-custom-500"></div>
        <div className=""><h2 className="text-custom-500">{t('General.Loading')}...</h2></div> 
      </div>
    </div>    
  );
};

