import { MessageTypes } from "./MessageEnums";
import { AlertCircle, AlertTriangle, CheckCircle } from "lucide-react";

export const NotificationsIconClass = {
    [MessageTypes.SUCCESS]: 'green',
    [MessageTypes.ERROR]: 'red',
    [MessageTypes.WARNING]: 'orange',
    [MessageTypes.INFO]: 'blue',
    [MessageTypes.QUESTION]: null,
}

export const NotificationsIcons = {
    [MessageTypes.ERROR]: AlertTriangle,
    [MessageTypes.INFO]: AlertCircle,
    [MessageTypes.SUCCESS]: CheckCircle,
    [MessageTypes.WARNING]: AlertCircle,
    [MessageTypes.QUESTION]: null,
};