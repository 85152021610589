

const menuData: any = [
    {
        label: 'menu',
        isTitle: true,
    },
    {
        id: "home",
        label: 'Menu.Home',
        link: "/",
    },
    {
        id: "products",
        label: 'Menu.Products',
        link: "/Page-in-Maintenance",
    },
    {
        id: "services",
        label: 'Menu.Services',
        link: "/Page-in-Maintenance",
    },
    {
        id: "brands",
        label: 'Menu.Brands',
        link: "/Brands-and-Manufacturers",
    },
    {
        id: "categories",
        label: 'Menu.Categories',
        link: "/Page-in-Maintenance",
    },
    {
        id: "contactUs",
        label: 'Menu.ContactUs',
        link: "/Contact-Us",
    },
    {
        id: "aboutUs",
        label: 'Menu.AboutUs',
        link: "/About-Us",
    }
    /*{
        id: "dashboard",
        label: 'Dashboards',
        link: "/Page-in-Maintenance",
        icon: <MonitorDot />,
        subItems: [
            {
                id: 'analyticsdashboard',
                label: 'Analytics',
                link: '/dashboards-analytics',
                parentId: "dashboard"
            },
            {
                id: 'ecommercedashboard',
                label: 'Ecommerce',
                link: '/dashboard',
                parentId: "dashboard"
            },
            {
                id: 'emaildashboard',
                label: 'Email',
                link: '/dashboards-email',
                parentId: "dashboard"
            },
            {
                id: 'hrdashboard',
                label: 'HR',
                link: '/dashboards-hr',
                parentId: "dashboard"
            },
            {
                id: 'socialdashboard',
                label: 'Social',
                link: '/dashboards-social',
                parentId: "dashboard"
            },
        ]
    },*/
];

export { menuData };