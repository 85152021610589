import React from "react"
import { withTranslation } from "react-i18next"
import img from "assets/images/AboutUs.resources/High-Quality_1.jpg"

const AboutUs = (props: any) => {
    return(        
        <React.Fragment>
            <section className="relative py-14" id="about">
                <div className="row mb-20">
                    <div className="mx-auto text-center xl:max-w-3xl">
                        <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('AboutUs.Title1') }</span></span></h1>
                        <p className="text-xl text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Description1-2') }</p>
                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 mt-20 lg:grid-cols-12">
                        <div className="lg:col-span-5">
                            <h1 className="mb-3 leading-normal text-center">{ props.t('AboutUs.Description1-1') }</h1>
                            <p className="mb-6 text-lg text-slate-500 dark:text-zink-200 text-center">{ props.t('AboutUs.Description1-3') }</p>
                        </div>
                        <div className="text-center lg:col-span-6">
                            <img src={img} alt="" className="shadow-lg ltr:lg:ml-auto rtl:lg:mr-auto rounded-xl" />
                        </div>
                    </div>
                </div>
                <div className="row mb-0">
                    <div className="mx-auto text-center xl:max-w-3xl">
                        <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('AboutUs.Title2') }</span></span></h1>
                        <p className="text-xl text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Description2-1') }</p>
                    </div>
                    <div className="p-10 mt-20 rounded-md bg-slate-800 dark:bg-zink-700">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                            <div className="text-center">
                                <h3 className="mb-2 text-slate-50 dark:text-zink-50">+ <span className="counter-value">150</span></h3>
                                <p className="text-16 text-slate-400 dark:text-zink-200">{ props.t('AboutUs.Products') }</p>
                            </div>
                            <div className="text-center">
                                <h3 className="mb-2 text-slate-50 dark:text-zink-50">+ <span className="counter-value">10</span></h3>
                                <p className="text-16 text-slate-400 dark:text-zink-200">{ props.t('AboutUs.Experience') }</p>
                            </div>
                            <div className="text-center">
                                <h3 className="mb-2 text-slate-50 dark:text-zink-50">+ <span className="counter-value">250</span></h3>
                                <p className="text-16 text-slate-400 dark:text-zink-200">{ props.t('AboutUs.Projects') }</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 mt-20 lg:grid-cols-12 text-center">
                        <div className="lg:col-span-12">
                            <h1 className="mb-3 leading-normal">{ props.t('AboutUs.Description2-2') }</h1>
                            <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Description2-3') }</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">        
                    <div className="grid items-center grid-cols-1 gap-6 mt-20 lg:grid-cols-12">
                        <div className="lg:col-span-6 text-center">
                            <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('AboutUs.Title3') }</span></span></h1>                
                            <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Description3-1') }</p>
                        </div>
                        <div className="text-center lg:col-span-6">
                            <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('AboutUs.Title4') }</span></span></h1>
                            <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Description4-1') }</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="mx-auto text-center xl:max-w-3xl">
                        <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('AboutUs.Title5') }</span></span></h1>            
                    </div>
                    <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 lg:grid-cols-2 text-center">
                        <div className="">
                            <div className="">
                                <h3 className="mb-1 leading-normal">{ props.t('AboutUs.Subtitle5-1') }</h3>
                            </div>
                            <div className="">
                                <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Subdescription5-1') }</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="">
                                <h3 className="mb-1 leading-normal">{ props.t('AboutUs.Subtitle5-2') }</h3>
                            </div>
                            <div className="">
                                <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Subdescription5-2') }</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="">
                                <h3 className="mb-1 leading-normal">{ props.t('AboutUs.Subtitle5-3') }</h3>
                            </div>
                            <div className="">
                                <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Subdescription5-3') }</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="">
                                <h3 className="mb-1 leading-normal">{ props.t('AboutUs.Subtitle5-4') }</h3>
                            </div>
                            <div className="">
                                <p className="mb-6 text-lg text-slate-500 dark:text-zink-200">{ props.t('AboutUs.Subdescription5-4') }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
};

export default withTranslation()(AboutUs);