// Images
import logo from "assets/images/SOILOGO.png";
import product1 from "assets/images/Index.resources/SpareParts_2.jpg"
import product2 from "assets/images/Index.resources/SoftwareDevelopment_2.jpg"
import product3 from "assets/images/Index.resources/Traceability_2.jpg"
import product4 from "assets/images/Index.resources/AACC Maintenance_2.png"
import product5 from "assets/images/Index.resources/Air-conditioner_2.jpg"
import product6 from "assets/images/Index.resources/Electrical_2.jpg"
import { MoveRight } from "lucide-react";
import React from 'react';
import { withTranslation } from 'react-i18next';
import Card from "Common/Components/Card";

const Home = (props: any) => {
    return (        
        <React.Fragment>
            <section className="relative pb-10">
                <div className="absolute bottom-0 right-0 w-64 h-64 bg-custom-500/10 blur-3xl"></div>
                <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto">
                    <div className="grid items-center grid-cols-1 gap-6 mt-20 lg:grid-cols-12">
                        <div className="relative lg:col-span-5">
                            <img src={logo} alt="" className="relative inline-block rounded-md"/>
                        </div>
                        <div className="ml-auto lg:col-span-5 lg:col-start-8">
                            <div className="text-center">
                                <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{props.t("Home.AboutUs")}</span></span></h1>                            
                            </div>                            
                            <h2 className="mb-3 leading-normal">{props.t("Home.AboutUsSubtitle")}</h2>
                            <p className="mb-5 text-lg text-slate-500 dark:text-zinc-400">{props.t("Home.AboutUsDescription")}</p>
                            <a href="/About-Us" className="text-custom-500 text-16">{props.t("Home.Learn More")} <MoveRight className="inline-block w-4 h-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180" /></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative pb-32">
                <div className="text-center py-5">
                    <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{props.t("Home.Our Services")}</span></span></h1>                        
                </div>
            </section>
            <section className="relative pb-8 pt-10">
                <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-3 xl:grid-cols-12 gap-x-5 gap-y-36">
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product1} link="/Element-Request" text_alignment="text-center" title={props.t("Home.Products1")}></Card>
                        </div>
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product2} link="#!" text_alignment="text-center" title={props.t("Home.Products2")}></Card>
                        </div>
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product3} link="#!" text_alignment="text-center" title={props.t("Home.Products3")}></Card>
                        </div>
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product4} link="#!" text_alignment="text-center" title={props.t("Home.Products4")}></Card>
                        </div>
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product5} link="#!" text_alignment="text-center" title={props.t("Home.Products5")}></Card>
                        </div>
                        <div className="col-span-1 xl:col-span-4">
                            <Card img={product6} link="#!" text_alignment="text-center" title={props.t("Home.Products6")}></Card>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        
    );
};

export default withTranslation()(Home);
