import React from "react"
import { withTranslation } from "react-i18next";
import { imagePaths } from "./imagePaths";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/zoom';
import { MoveRight } from "lucide-react";
import { publicRoutes } from "Routes/allRoutes";
import ElementRequestForm from "pages/ElementRequestForm";

const Brands = (props: any) => {

    return (
        <React.Fragment>
            <section className="relative py-16">
                <div className="container ">
                    <div className="mb-4 text-center items-center justify-center rounded-md">
                        <div className="row mb-4">
                            <div className="d-flex justify-content-center">
                                <h1 className="mb-6 leading-normal"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('Brands.Catalog') }</span></span></h1>                    
                            </div>
                        </div>            
                    </div>
                    <div className="">
                        <div className="row mb-4">
                            <div className="text-center">
                                <h3 className="mb-0 leading-normal">{ props.t('Brands.Title1') }</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-center text-center">
                                <a href="/Brands-and-Manufacturers" className="text-custom-500 text-16">{ props.t('Brands.eShopLink') } <MoveRight className="inline-block w-4 h-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180" /></a>
                            </div>
                        </div>
                        <div className="row">
                            <Swiper                         
                                slidesPerView={1}
                                spaceBetween={50}
                                navigation={ { enabled: true } }
                                breakpoints={{                                    
                                    640: {
                                        slidesPerView: 3,
                                    },
                                }}
                                autoplay={true}
                                modules={[Navigation, Autoplay]}
                                >
                                    {imagePaths.map((path, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="p-5">
                                                <img src={path} alt=""/>  
                                            </div>
                                        </SwiperSlide>
                                    ))}                                
                            </Swiper>
                        </div>
                        <div className="row">
                            <h3 className="text-16 text-center">{ props.t('Brands.RequestProduct') } <a className="text-custom-500 text-16" href={publicRoutes.find(key => key.component === ElementRequestForm)?.path}>{ props.t('Brands.ButtonHere') }</a></h3>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
};

export default withTranslation()(Brands);