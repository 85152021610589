import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = (props: any) => {
    return (
        <>
            <footer className="relative pt-20 pb-12 bg-slate-800 dark:bg-zink-700">
                <div className="container 2xl:max-w-[87.5rem] px-1 mx-auto">
                    <div className="relative z-10 grid grid-cols-12 gap-5 xl:grid-cols-12">
                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-4">
                            <h5 className="mb-4 font-medium tracking-wider text-slate-50 dark:text-zink-50">{ props.t('Footer.Products') }</h5>
                            <ul className="flex flex-col gap-3 text-15">
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear" >
                                        { props.t('Footer.Products1') }
                                    </Link>                                    
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Products2') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Products3') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Products4') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Products5') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Products6') }</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-4">
                            <h5 className="mb-4 font-medium tracking-wider text-slate-50 dark:text-zink-50">{ props.t('Footer.Services') }</h5>
                            <ul className="flex flex-col gap-3 text-15">
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Services1') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Services2') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Services3') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Services4') }</Link>
                                </li>
                                <li>
                                    <Link to={ {  } } className="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear">{ props.t('Footer.Services5') }</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-12 xl:col-span-4">
                            <h5 className="text-white mb-3">{ props.t('Footer.Contact') }</h5>
                            <p className="text-white">
                                <strong>{ props.t('Footer.Address') }:</strong><br></br>
                                Cesar Augusto #315,<br></br>
                                Los Reyes, CP 78170,<br></br>
                                San Luis Potosí, S.L.P<br></br>
                                <strong>
                                    { props.t('Footer.Phone') }:<br></br>
                                </strong><a href="tel:+524442654082" className="text-white">+52 444-265-4082</a><br></br>
                                <strong>
                                    { props.t('Footer.Email') }:<br></br>
                                </strong><a href="mailto:sales@soi-soluciones.com?subject=Questions" className="text-white">sales@soi-soluciones.com</a><br></br>
                            </p>
                        </div>
                    </div>
                    <div className="mt-12 text-center text-slate-400 dark:text-zink-200 text-16">
                        <p>
                            <div className="copyright">
                                &copy; Copyright <strong><span>SOI</span></strong>. { props.t('Footer.Rights') } - { props.t('Footer.Developed') } <strong>uElectronic Solutions</strong>
                            </div>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default withTranslation()(Footer);
