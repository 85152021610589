import flagUs from "assets/images/flags/20/us.svg";
import flagMx from "assets/images/flags/mx.svg";

const languages = {  
  es: {
    label: 'Layout.Header.Languages.Spanish',
    flag: flagMx,
  },
  en: {
    label: 'Layout.Header.Languages.English',
    flag: flagUs,
  },
}

export default languages
