enum MessageTypes {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    QUESTION = 'question'
}

enum MessageButtons {
    OK = 1,
    YES = 2,
    NO = 3,
    CANCEL = 4
}

enum MessageColors{
    OK = "#0141BE",
    YES = "#16A085",
    NO = "#C0392B",
    CANCEL = "#95A5A6"
}

export {
    MessageTypes,
    MessageButtons,
    MessageColors
}