import axios, { AxiosRequestConfig, Method } from 'axios';
import i18n from "i18next";
import { ApiResponse } from './APIResponse';

export async function axios_helper_async<T>(
    url: string,
    method: Method = 'GET',
    body: any = null,
    errorTitle: string | null = null,
    errorMsg: string | null = null,
    options: AxiosRequestConfig | null = null
): Promise<ApiResponse<T>> {
    try {
        const config: AxiosRequestConfig = {
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            headers: { 'Content-Type': 'application/json', ...(options?.headers || {}) },
            data: method !== 'GET' ? body : null,
            ...options,
        };

        const response = await axios(config);

        return {
            data: response.data as T,
            message: '',
            isSuccess: true,
        };
    } catch (error: any) {
        let message = i18n.t('AxiosAPI.UnexpectedError');

        if (error.response && error.response.data) {
            message = error.response.data.message || error.response.data ||  i18n.t('AxiosAPI.NoneMessage');
        } else if (error.request) {
            message = i18n.t('AxiosAPI.NoneResponse');
        } else if (error.message) {
            message = error.message;
        }

        return {
            data: null,
            message: message,
            isSuccess: false,
        };
    }
}
