import React, { useState, useEffect } from "react"
import { Phone } from "lucide-react";
import { Mail } from "lucide-react";
import { MapPin } from "lucide-react";
import { withTranslation } from "react-i18next";
import { useNotifications } from "Common/Components/NotificationProvider";
import { MessageTypes } from 'Common/constants/MessageEnums';
import { axios_helper_async } from "Common/Models/Axios";
import { useOverlay } from "Common/Components/OverlayContext";
import { ContactUsViewModel } from "Common/ViewModels/ContactUsViewModel";


const ContactUs = (props: any) => {
    const modelFields: Record<keyof ContactUsViewModel, string> = {
        email: 'email',
        message: 'message',
        name: 'name',
        phone: 'phone',
        subject: 'subject',
        errors: 'errors'
    }

    const initialModel = {
        email: '',
        message: '',
        name: '',
        phone: '',
        subject: '',
        errors: {}
    }

    const { show } = useNotifications();

    const { showOverlay, hideOverlay } = useOverlay();

    const [model, setModel] = useState<ContactUsViewModel>({
        email: '',
        message: '',
        name: '',
        phone: '',
        subject: '',
        errors: {
            email: '',
            message: '',
            name: '',
            phone: '',
            subject: ''
        }
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setModel(prevModel => ({
            ...prevModel,
            [name]: value
        }))
    }

    const validateModel = () => {
        let errors: ContactUsViewModel['errors'] = {};
        if(!model.email) errors.email = 'ContactUs.Validations.Email';
        if(!model.name) errors.name = 'ContactUs.Validations.Name';
        if(!model.subject) errors.subject = 'ContactUs.Validations.Subject';
        if(!model.message) errors.message = 'ContactUs.Validations.Message';

        setModel(prevModel => ({
            ...prevModel,
            errors
        }));

        if(Object.keys(errors).length!== 0) show(props.t('General.CompleteData'), MessageTypes.ERROR);

        return Object.keys(errors).length === 0;
    }

    const handleClick = async () => {
        if(validateModel()){
            showOverlay();
            let response = await axios_helper_async<string>('/SOI/Send-Contact-Us-Form', 'POST', model);
        
            if(!response.isSuccess){
                show(props.t('AxiosAPI.InternalError'), MessageTypes.ERROR);
            }
            else{
                show(props.t('ContactUs.Alerts.EmailSent'), MessageTypes.SUCCESS);
                setModel(initialModel);
            }
            hideOverlay();
        }
    }

    useEffect(() => {
    },[model])

    return(
        <React.Fragment>
            <div className="relative py-14">
                <h1 className="mb-6 leading-normal text-center" data-aos="fade-left" data-aos-delay="300"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('ContactUs.ContactUs') }</span></span></h1>                
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="xl:col-span-3 bg-slate-800 card !mb-0" data-aos="fade-right" data-aos-delay="300">
                        <div className="felx card-body">
                            <div className="grid grid-cols-1 justify-center">
                                <div className="grid grid-cols-3 items-center py-3">
                                    <div className="flex justify-center items-center col-span-1">
                                        <MapPin className="text-white h-10 w-10" />
                                    </div>
                                    <div className="flex items-center col-span-2">
                                        <div>
                                            <h4 className="text-slate-50">{ props.t('ContactUs.Location') }:</h4>
                                            <p className="text-slate-50">
                                                Cesar Augusto #315, Los Reyes, CP 78170, San Luis Potosí, S.L.P
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 items-center py-3">
                                    <div className="flex justify-center items-center col-span-1">
                                        <Mail className="text-white h-10 w-10" />
                                    </div>
                                    <div className="flex items-center col-span-2">
                                        <div>
                                            <h4 className="text-slate-50">{ props.t('ContactUs.Email') }:</h4>
                                            <p className="text-slate-50"><a href="mailto:sales@soi-soluciones.com?subject=Questions">sales@soi-soluciones.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 items-end py-3">
                                    <div className="flex justify-center items-center col-span-1">
                                        <Phone className="text-white h-10 w-10" />
                                    </div>
                                    <div className="flex items-center col-span-2">
                                        <div>
                                            <h4 className="text-slate-50">{ props.t('ContactUs.Phone') }:</h4>
                                            <p className="text-slate-50"><a href="tel:+524442654082">+52 444-265-4082</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-9" data-aos="fade-left" data-aos-delay="300">
                        <form id="MainForm">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mb-2">
                                <div>
                                    <label htmlFor={modelFields.name} className="inline-block mb-2 text-base font-medium text-">{ props.t('ContactUs.Name') } <span className="text-red-500">*</span></label>
                                    <input type="text" 
                                        id={modelFields.name}
                                        value={model.name}
                                        name={modelFields.name}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.name ? "border-red-500":"border-slate-200"}`} placeholder={ props.t('ContactUs.Name') } />
                                        {model.errors.name && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.name)}</div>}
                                </div>
                                <div>
                                    <label htmlFor={modelFields.email} className="inline-block mb-2 text-base font-medium">{ props.t('ContactUs.Email') } <span className="text-red-500">*</span></label>
                                    <input type="email" 
                                        id={modelFields.email} 
                                        value={model.email}
                                        name={modelFields.email}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.email ? "border-red-500":"border-slate-200"}`} placeholder={ props.t('ContactUs.Email') } />
                                        {model.errors.email && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.email)}</div>}
                                </div>
                                <div>
                                    <label htmlFor={modelFields.phone} className="inline-block mb-2 text-base font-medium">{ props.t('ContactUs.Phone') }</label>
                                    <input type="tel" 
                                        id={modelFields.phone}
                                        name={modelFields.phone}
                                        value={model.phone}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.phone ? "border-red-500":"border-slate-200"}`} placeholder={ props.t('ContactUs.Phone') } />
                                        {model.errors.phone && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.phone)}</div>}
                                </div>
                                <div>
                                    <label htmlFor={modelFields.subject} className="inline-block mb-2 text-base font-medium">{ props.t('ContactUs.Subject') } <span className="text-red-500">*</span></label>
                                    <input type="text" 
                                        id={modelFields.subject}
                                        name={modelFields.subject}
                                        value={model.subject}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.subject ? "border-red-500":"border-slate-200"}`} placeholder={ props.t('ContactUs.Subject') } />
                                        {model.errors.subject && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.subject)}</div>}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-5">
                                <div>
                                    <label htmlFor={modelFields.message} className="inline-block mb-2 text-base font-medium">{ props.t('ContactUs.Message') } <span className="text-red-500">*</span></label>
                                    <textarea 
                                        id={modelFields.message} 
                                        value={model.message}
                                        name={modelFields.message}
                                        onChange={handleChange}
                                        rows={5} className={`form-input ${model.errors.message ? "border-red-500":"border-slate-200"}`} placeholder={ props.t('ContactUs.Message') }></textarea>
                                        {model.errors.message && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.message)}</div>}
                                </div>
                            </div>
                            <div className="mt-5 flex justify-center">
                                <button type="button" onClick={handleClick} className="text-white btn bg-custom-500 border-custom-500 w-100">{ props.t('ContactUs.BtnSendMessage') }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default withTranslation()(ContactUs);