import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { useNotifications } from "Common/Components/NotificationProvider";
import { MessageTypes } from 'Common/constants/MessageEnums';
import { axios_helper_async } from "Common/Models/Axios";
import { ElementRequestFormViewModel } from "Common/ViewModels/ElementRequestFormViewModel";
import { Categories } from "Common/Models/Categories";
import { Brands } from "Common/Models/Brands";
import { useOverlay } from "Common/Components/OverlayContext";

const modelFields: Record<keyof ElementRequestFormViewModel, string> = {
    brand: 'brand',
    category: 'category',
    description: 'description',
    email: 'email',
    name: 'name',    
    numberPart: 'numberPart',
    brands: '',
    otherBrand: 'otherBrand',
    categories: '',
    otherCategory: 'otherCategory',
    phone: 'phone',
    errors: 'errors'
};

const ElementRequestForm = (props: any) => {
    const initialState = {
        brand: '',
        category: '',
        description: '',
        email: '',
        name: '',
        numberPart: '',
        brands: [],
        otherBrand: '',
        categories: [],
        otherCategory: '',
        phone: '',
        errors: {}
    };
    //
    const { show } = useNotifications();
    const { showOverlay, hideOverlay } = useOverlay();
    const [model, setModel] = useState<ElementRequestFormViewModel>({
        brand: '',
        category: '',
        description: '',
        email: '',
        name: '',
        numberPart: '',
        brands: [],
        otherBrand: '',
        categories: [],
        otherCategory: '',
        phone: '',
        errors: { }
    });
    const [resetModel, setResetModel] = useState(true);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setModel(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };            

    const getBrands = async () => {
        const response = await axios_helper_async<Brands[]>('/SOI/Get-Brands', 'GET');
        if(response.isSuccess){
            if(response.data !== null) {
                model.brands = response.data.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            }
        }
    };

    const getCategories = async () => {
        const response = await axios_helper_async<Categories[]>('/SOI/Get-Categories', 'GET');
        if(response.isSuccess){
            if(response.data !== null) {
                model.categories = response.data.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            }
        }
    };
    
    useEffect(() => {
        if (resetModel) {
            getBrands();
            getCategories();
            setResetModel(false);
        }
    }, [resetModel]);

    useEffect(() => {
    }, [model]);


    const validateModel = () => {
        let errors: ElementRequestFormViewModel['errors'] = {};
        // if (!model.brand) errors.brand = 'ElementRequestForm.Validations.Brand';
        // if (!model.category) errors.category = 'ElementRequestForm.Validations.Category';
        if (!model.description) errors.description = 'ElementRequestForm.Validations.Description';
        if (!model.email) errors.email = 'ElementRequestForm.Validations.Email';
        if (!model.name) errors.name = 'ElementRequestForm.Validations.Name';
        if (!model.numberPart) errors.numberPart = 'ElementRequestForm.Validations.NumberPart';
        setModel(prevModel => ({
            ...prevModel,
            errors
        }));
        
        if(Object.keys(errors).length !== 0) show(props.t('General.CompleteData'), MessageTypes.ERROR);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {  
        if (validateModel()) {
            showOverlay();      
            let response = await axios_helper_async<string>('/SOI/Send-Element-Request', 'POST', model);
        
            if(!response.isSuccess){
                show(props.t('AxiosAPI.InternalError'), MessageTypes.ERROR);
            }
            else{
                show(props.t('ElementRequestForm.Alerts.EmailSent'), MessageTypes.SUCCESS);
                setModel(initialState);
                setResetModel(true);
            }
            hideOverlay();
        }
    };

    return (
        <React.Fragment>
            <div className="relative py-14">
                <h1 className="mb-6 leading-normal text-center" data-aos="fade-left" data-aos-delay="300"><span className="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"><span className="relative text-custom-500">{ props.t('ElementRequestForm.Title1') }</span></span></h1>
                <div className="grid grid-cols-1 gap-5 py-14">
                    <div className="" data-aos="fade-left" data-aos-delay="300">
                        <form id="MainForm">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mb-2">
                                {/* Name input */}
                                <div>
                                    <label htmlFor={modelFields.name} className="inline-block mb-2 text-base font-medium text-">{ props.t('ElementRequestForm.Name') } <span className="text-red-500">*</span></label>                                    
                                    <input
                                        type="text"
                                        id={modelFields.name}
                                        name={modelFields.name}
                                        value={model.name}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.name ? "border-red-500":"border-slate-200"}`}
                                        placeholder={props.t('ElementRequestForm.Name')}
                                    />                                                                        
                                    {model.errors.name && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.name)}</div>}
                                </div>
                                {/* Email input */}
                                <div>
                                    <label htmlFor={modelFields.email} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Email')} <span className="text-red-500">*</span></label>
                                    <input
                                        type="email"
                                        id={modelFields.email}
                                        name={modelFields.email}
                                        value={model.email}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.email ? "border-red-500":"border-slate-200"}`}
                                        placeholder={props.t('ElementRequestForm.Email')}
                                    />
                                    {model.errors.email && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.email)}</div>}
                                </div>

                                {/* Phone input */}
                                <div>
                                    <label htmlFor={modelFields.phone} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Phone')}</label>
                                    <input
                                        type="tel"
                                        id={modelFields.phone}
                                        name={modelFields.phone}
                                        value={model.phone}
                                        onChange={handleChange}                                        
                                        placeholder={props.t('ElementRequestForm.Phone')}                                        
                                        className="form-input border-slate-200 container"
                                    />
                                </div>

                                {/* NumberPart input */}
                                <div>
                                    <label htmlFor={modelFields.numberPart} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.NumberPart')} <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        id={modelFields.numberPart}
                                        name={modelFields.numberPart}
                                        value={model.numberPart}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.numberPart ? "border-red-500":"border-slate-200"}`}
                                        placeholder={props.t('ElementRequestForm.NumberPart')}
                                    />
                                    {model.errors.numberPart && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.numberPart)}</div>}
                                </div>

                                {/* Brand Select */}
                                <div>
                                    <label htmlFor={modelFields.brand} className="inline-block mb-2 text-base font-medium text-">{props.t('ElementRequestForm.Brand')}</label>
                                    <Select
                                        className="border-slate-200"
                                        name={modelFields.brand}
                                        options={model.brands}
                                        placeholder={props.t('General.SelectItem')}
                                    />
                                    {/* {model.errors.brand && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.brand)}</div>} */}
                                </div>

                                {/* Category Select */}
                                <div>
                                    <label htmlFor={modelFields.category} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Category')}</label>
                                    <Select
                                        className="border-slate-200"
                                        name={modelFields.category}
                                        options={model.categories}
                                        placeholder={props.t('General.SelectItem')}                                        
                                    />
                                    {/* {model.errors.category && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.category)}</div>} */}
                                </div>

                                {/* OtherBrand input */}
                                <div>
                                    <label htmlFor={modelFields.otherBrand} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Other')}</label>
                                    <input
                                        type="text"
                                        id={modelFields.otherBrand}
                                        name={modelFields.otherBrand}
                                        value={model.otherBrand}
                                        onChange={handleChange}
                                        className="form-input border-slate-200"
                                        placeholder={props.t('ElementRequestForm.Other')}
                                    />
                                    {/* {model.errors.otherBrand && !model.brand && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.otherBrand)}</div>} */}
                                </div>

                                {/* OtherCategory input */}
                                <div>
                                    <label htmlFor={modelFields.otherCategory} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Other')}</label>
                                    <input
                                        type="text"
                                        id={modelFields.otherCategory}
                                        name={modelFields.otherCategory}
                                        value={model.otherCategory}
                                        onChange={handleChange}
                                        className="form-input border-slate-200"
                                        placeholder={props.t('ElementRequestForm.Other')}
                                    />
                                    {/* {model.errors.otherCategory && !model.category && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.otherCategory)}</div>} */}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-5">
                                {/* Description textarea */}
                                <div>
                                    <label htmlFor={modelFields.description} className="inline-block mb-2 text-base font-medium">{props.t('ElementRequestForm.Description')} <span className="text-red-500">*</span></label>
                                    <textarea
                                        id={modelFields.description}
                                        name={modelFields.description}
                                        rows={5}
                                        value={model.description}
                                        onChange={handleChange}
                                        className={`form-input ${model.errors.description ? "border-red-500":"border-slate-200"}`}
                                        placeholder={props.t('ElementRequestForm.Description')}
                                    ></textarea>
                                    {model.errors.description && <div className="mt-1 text-sm text-red-500">{props.t(model.errors.description)}</div>}
                                </div>
                            </div>
                            <div className="mt-5 flex justify-center">
                                <button type="button" onClick={handleSubmit} className="text-white btn bg-custom-500 border-custom-500 w-100">{ props.t('ElementRequestForm.BtnSendMessage') }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default withTranslation()(ElementRequestForm);