import { MessageTypes } from 'Common/constants/MessageEnums';
import React, { createContext, useState, useCallback, ReactNode } from 'react';
import Alert from './Alert';
import NotificationContextType from 'Common/Models/NotificationContextType';
import Notifications from 'Common/Models/Notifications';
import { NotificationsIcons, NotificationsIconClass } from 'Common/constants/NotificationsColors';

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotifications = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notifications[]>([]);

  const removeNotification = useCallback((id: number) => {
    setNotifications(currentNotifications => currentNotifications.filter(notification => notification.Id !== id));
  }, []);

  const show = (message: string, type: MessageTypes, title?: string) => {
    const id = new Date().getTime();
    setNotifications(prevNotifications => [...prevNotifications, { Id: id, Title: title, Message: message, Type: type }]);
    setTimeout(() => removeNotification(id), 10000);
  };
  
  
  return (
    <NotificationContext.Provider value={{ show }}>
      {children}
      <div className="fixed top-5 right-5 z-[9999] space-y-2">
        {notifications.map(({ Id, Title, Message, Type }) => {
          const NotificationIcon = NotificationsIcons[Type];
          const iconClass = NotificationsIconClass[Type];
          return (
            <Alert key={Id} className={`relative p-3 pr-12 text-sm text-${iconClass}-500 border border-t-2 border-transparent rounded-md bg-${iconClass}-50 border-t-${iconClass}-300 grid grid-flow-col auto-cols-max gap-4 items-center`}>
                {NotificationIcon && (
                  <NotificationIcon className={`w-8 text-${iconClass}-500 h-8`} />
                )}
                <Alert.Bold>{Title ? <h6 className={`mb-1`}>{Title}: </h6> : null}{Message}</Alert.Bold>
                <Alert.Close className={`absolute top-0 bottom-0 right-0 p-3 text-${iconClass}-200 transition hover:text-${iconClass}-500`} />
            </Alert>
            // <Alert key={Id} className={`gap-3 p-4 text-sm rounded-md bg-${iconClass}-50`}>
            //   <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-custom-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
            //   {NotificationIcon && (
            //     <NotificationIcon className={`inline-block w-4 text-${iconClass}-500 h-4 mt-0.5 shrink-0`} />
            //   )}
            //   <Alert.Content>
            //     {Title ? <h6 className={`mb-1`}>{Title}</h6> : null}
            //     <ul className={`ml-2`}>
            //         <li><h6>{Message}</h6></li>
            //     </ul>
            //   </Alert.Content>
            // </Alert>
          );
        })}
      </div>
    </NotificationContext.Provider>
  );
};