import React from "react";
import { MoveRight } from "lucide-react";

type CardProps = {
    img?: string;
    title?: string;
    text_alignment?: string;
    paragraph?: string;
    link?: string;
    linkText?: string;
};

const Card: React.FC<CardProps> = ({ img, title, text_alignment, paragraph, link, linkText }) => {
    return(
        <React.Fragment>
            <div className="transition-all duration-300 ease-linear -mt-36 card hover:-translate-y-2 dark:bg-zink-600">
                <div className="p-6">
                    {img && (link ? (<a href={link}><img src={img} alt="" className="rounded-md shadow" /></a>) : (<img src={img} alt="" className="rounded-md shadow" />))}
                    <div className="mt-6">
                        {title && (<h6 className={`mt-3 mb-2 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 8xl:text-8xl 9xl:text-9xl ${text_alignment}`}><a href={link}>{title}</a></h6>)}
                        {paragraph && (<p className="mb-3 text-slate-500 dark:text-zink-200 text-16">{paragraph}</p>)}
                        {linkText && (<a href={link} className="text-custom-500 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 8xl:text-8xl 9xl:text-9xl">{linkText} <MoveRight className="inline-block w-4 h-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180" /></a>)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Card;