import React from "react";

// Image
import logo from "assets/images/SOILOGO.png"
import maintenance from "assets/images/auth/maintenance.png";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Maintenance = (props: any) => {

    return (
        <React.Fragment>
            <section className="relative py-14" id="about">
                <Link to="/">
                    <img src={logo} alt="" className="h-32 mx-auto dark:block" />
                </Link>

                <div className="mt-10">
                    <img src={maintenance} alt="" className="mx-auto h-72" />
                </div>
                <div className="mt-8 text-center">
                    <h4 className="mb-2 text-custom-500">{props.t('Maintenance.Title')}</h4>
                    <p className="mb-6 text-slate-500 dark:text-zink-200">{props.t('Maintenance.Subtitle')}</p>
                    <Link to="/" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                        <span className="align-middle">{props.t('Maintenance.BtnBack')}</span>
                    </Link>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withTranslation()(Maintenance);